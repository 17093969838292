body {
  background:#fff;
  color:#444;
}

body.work-time header,
body.work-time #timer-container,
body.work-time #clock-inner {
  background:#ee7373;
  transition:background-color 1s;
}

body.break-time header,
body.break-time #timer-container,
body.break-time #clock-inner {
  background:#77c477;
  transition:background-color 1s;
}

/* HEADER */

header {
  border-bottom:1px solid rgba(255, 255, 255, 30%);
}

header > .container {
  display:flex;
}

header h1 {
  background:url(/assets/logo.png) 0 50% no-repeat;
  font-size:26px;
  padding:.5rem 0 .5rem 2.2rem;
  margin:0;
  color:#fff;
}

header .btn {
  color:#fff;
  box-shadow:none !important;
  border:0;
  opacity:.8;
}

header .btn:hover {
  color:#fff;
  opacity:1;
}

/* TIMER */

#timer-container {
  text-align:center;
  padding:2rem 1rem;
}

/* CLOCK */

#clock-container {
  display:flex;
  align-items:center;
  justify-content:center;
  margin:0 auto 2rem;
  width:300px;
  height:300px;
  position:relative;
  overflow:hidden;
}

#clock {
  background:rgba(255, 255, 255, 15%);
  /*border:1px solid #f0f0f0;*/
  display:flex;
  align-items:center;
  justify-content:center;
}

#clock,
#clock .clock-mask,
#clock .clock-fill {
  width:100%;
  height:100%;
  border-radius:50%;
  position:absolute;
  z-index:5;
}

#clock .clock-mask {
  clip-path:inset(0 0 0 150px);
}

#clock .clock-fill {
  background:#fff;
  clip-path:inset(0 150px 0 0);
}

#clock-inner {
  font-size:74px;
  color:#fff;
  width:280px;
  height:280px;
  border-radius:50%;
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:10;
}

/* BUTTONS */

#timer-buttons-container > .btn {
  margin:0;
  width:200px;
}

#timer-buttons-container > .btn {
  background:transparent;
  color:#fff;
  border-color:#fff;
  box-shadow:none;
}

#timer-buttons-container > .btn:hover {
  background:rgba(0, 0, 0, 10%);
}

#timer-buttons-container > .btn > i {
  margin-right:.5rem;
}

/* STAGES */

#timer-stages {
  background:#fafafa;
  margin-bottom:4rem;
  padding:3rem 1rem 3rem;
  text-align:center;
}

#timer-stages > .container {
  max-width:550px;
}

#timer-stages .stage {
  font-size:12px;
  background:#fff;
  color:#aaa;
  display:inline-block;
  position:relative;
  border:1px solid #f5f5f5;
  border-radius:5px;
  padding:.5rem 20px .5rem 32px;
  margin:0 0 .5rem;
  outline:0;
}

#timer-stages .stage.completed {
  color:#ddd;
}

#timer-stages .stage:after {
  content:"";
  background:inherit;
  position:absolute;
  border:inherit;
  border-left:transparent;
  border-bottom:transparent;
  border-radius:5px;
  top:3px;
  right:-11px;
  width:28px;
  height:28px;
  transform:rotate(45deg);
  z-index:999;
}

#timer-stages .stage:hover {
  background:#f0f0f0;
  color:#444;
}

#timer-stages .stage.active {
  background:#ee7373; 
  color:#fff;
  border:1px solid #ee7373;
}

#timer-stages .stage.active.break {
  background:#77c477;
  border:1px solid #77c477;
}

/* SETTINGS */

.modal .modal-header {
  padding:.7rem 1rem;
}

.modal .modal-header > .h4 {
  font-size:1.2rem;
}

.modal .form-group {
  font-size:.9rem;
  border-bottom:1px solid #f0f0f0;
  padding-bottom:.5rem;
}

.modal .form-group:last-of-type {
  border:0;
}

.custom-switch {
  text-align:right;
}

.custom-switch .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* FOOTER */

#footer {
  font-size:.75rem;
  color:#aaa;
  text-align:center;
}

/* DARK MODE */

body.dark-mode {
  background:#333;
}

body.dark-mode #timer-stages {
  background:#444;
}

body.dark-mode #timer-stages .stage:not(.active) {
  background:#333;
  border:1px solid #222;
}

body.dark-mode #timer-stages .stage:hover:not(.active) {
  background:#222;
  color:#ccc;
}

body.dark-mode #timer-stages .stage.completed {
  background:#555;
  color:#999;
}

body.dark-mode .modal-content {
  background:#444;
  color:#eee;
}

body.dark-mode .modal-header .close {
  color:#ccc;
}

body.dark-mode .modal-header,
body.dark-mode .modal .form-group {
  border-bottom-color:#333;
}

body.dark-mode .modal-footer {
  border-top-color:#333;
}

body.dark-mode .modal-body .form-control,
body.dark-mode .modal-body select,
body.dark-mode .modal-body .input-group-append {
  background-color:#eee;
  color:#222;
}

body.dark-mode .modal-footer .text-danger {
  color:#f999a2 !important;
}
